<template>
	<router-view v-slot="{Component}">
		<component :is="Component">
		</component>
	</router-view>
</template>

<script>
	// @ is an alias to /src
	export default {
		name: 'index',
		components: {}
	}
</script>
